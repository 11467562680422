import React, { MouseEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Goo } from '../Gooey';

const Container = styled.div<{
  children?: ReactNode;
  className?: string;
  onMouseMove: (e: MouseEvent) => void;
}>`
  position: relative;
  width: 100%;
  height: 100%;

  @keyframes blob_one {
    100% {
      transform: translate(-12.5%, -12.5%);
    }
  }

  @keyframes blob_two {
    100% {
      transform: translate(12.5%, -12.5%);
    }
  }

  @keyframes blob_three {
    100% {
      transform: translate(-5.5%, 7.5%);
    }
  }

  @keyframes blob_four {
    100% {
      transform: translate(-12.5%, -12.5%);
    }
  }
`;

const SVG = styled.svg`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

// This component is a simple example of using the Goo component, with some
// interactivity.
// At its core, all this is doing is animating SVG shapes (see /animations.scss)
// in an SVG filter wrapper to interpolate between paths as they near each other.

export const Metaballs = ({
  interactive,
  duration,
  color,
}: {
  interactive?: boolean;
  duration?: number;
  color?: string;
}) => {
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);

  // Generate random UUID to use as ID on Gooey element,
  // to allow for multiple Gooey instances on the same page with differing
  // intensity or composite values.
  // const gooeyId = uuidv4();

  // Set position of interactive ball.
  const handleMouseMove = (e: MouseEvent) => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setMousePosition({ x, y });
  };

  return (
    <Container onMouseMove={handleMouseMove}>
      <Goo intensity="strong" style={{ height: '150%', width: '150%' }}>
        <SVG>
          <g>
            {interactive && hasInteracted && (
              <circle cx={`${mousePosition.x}px`} cy={`${mousePosition.y}px`} r="75" fill={color}>
                <animate
                  attributeName="r"
                  values="70;80;70"
                  begin="0s"
                  dur="7s"
                  repeatCount="indefinite"
                />
              </circle>
            )}
            <circle
              cx="50%"
              cy="50%"
              r="150"
              fill={color}
              style={{
                animation: `blob_four ${duration}s ease-in-out -3s infinite alternate`,
              }}
            >
              <animate attributeName="r" values="120;150;120" dur="13s" repeatCount="indefinite" />
            </circle>
            <circle
              cx="60%"
              cy="45%"
              r="100"
              fill={color}
              style={{
                animation: `blob_three ${duration}s ease-in-out -3s infinite alternate`,
              }}
            >
              <animate attributeName="r" values="75;110;75" dur="16s" repeatCount="indefinite" />
            </circle>
            <circle
              cx="60%"
              cy="45%"
              r="90"
              fill={color}
              style={{
                animation: `blob_four ${duration}s ease-in-out -3s infinite alternate`,
              }}
            >
              <animate attributeName="r" values="65;90;65" dur="18s" repeatCount="indefinite" />
            </circle>
            <circle
              cx="45%"
              cy="50%"
              r="120"
              fill={color}
              style={{
                animation: `blob_two ${duration}s ease-in-out -3s infinite alternate`,
              }}
            >
              <animate attributeName="r" values="100;120;100" dur="28s" repeatCount="indefinite" />
            </circle>
            <circle
              cx="50%"
              cy="55%"
              r="90"
              fill={color}
              style={{
                animation: `blob_one ${duration}s ease-in-out -3s infinite alternate`,
              }}
            >
              <animate attributeName="r" values="60;90;60" dur="15s" repeatCount="indefinite" />
            </circle>
          </g>
        </SVG>
      </Goo>
    </Container>
  );
};

Metaballs.defaultProps = {
  interactive: false,
  duration: 22,
  color: '#515E69',
};
